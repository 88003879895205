/* eslint-disable no-unused-vars */
import {  createSlice } from "@reduxjs/toolkit";

const initialState = {
  document: {
    open: false,
    mode: "ADD",
    bulk: false,
    // payload: null,
    item_id_to_attach: null,
    selected_document: null,
    // props: {
    //   formData: {},
    // },
  },
  documentViewer: {
    open: false,
    type: "_id",
    selectedDocument: {},
  },
  document_links: {
    open: false,
    mode: "ADD",
    bulk: false,
    payload : null,
    // props: { item_id: null },
  },
  cloud_templates: {
    open: false,
    mode: "ADD",
    props: {},
  },
  reference_pointer: {
    open: false,
    mode: "ADD",
    bulk: false,
    payload: null,
  },
  task_template: {
    open: false,
    mode: "ADD",
    props: {
      formSchema: {},
      formData: {},
      selected: null,
    },
  },
  generate_tasks: {
    open: false,
    mode: "ADD",
    props: {},
  },
  tasks: {
    open: false,
    mode: "ADD",
    payload: null,
  },
  entity: {
    open: false,
    mode: "ADD",
    payload: null,
  },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    toggleModal: (state, action) => {
      const {
        kind,
        ...otherState
      } = action.payload;

      state[kind] = {
        ...state[kind],
        ...otherState
      }
      // const {
      //   kind,
      //   open = false,
      //   mode = "ADD",
      //   bulk = false,
      //   payload = null,
      //   item_id_to_attach = null,
      //   selected_document = null,
      //   item_id = null
      // } = action.payload;

      // state[kind].open = open;
      // state[kind].mode = mode;
      // state[kind].bulk = bulk;
      // state[kind].payload = payload;
      // state[kind].item_id = item_id;
      // state[kind].item_id_to_attach = item_id_to_attach;
      // state[kind].selected_document = selected_document;
    
    },

    // toggleDocumentModal: (state, action) => {
    //   const { mode = "ADD", open, item_id = null } = action.payload;
    //   state.document.open = open;
    //   state.document.mode = mode;
    //   state.document.props.item_id = item_id;

    // },
    // toggleDocumentLinksModal: (state, action) => {
    //   const { mode = "ADD", open, item_id = null } = action.payload;
    //   state.document_links.open = open;
    //   state.document_links.mode = mode;
    //   state.document_links.props.item_id = item_id;
    // },
    setDocumentFormData: (state, action) => {
      state.document.props.formData = action.payload;
    },
    // setTaskTemplateFormData: (state, action) => {
    //   state.document.props.formData = action.payload;
    // },
    toggleTaskTemplateModal: (state, action) => {
      const { mode = "ADD", open, selected = null } = action.payload;
      state.task_template.open = open;
      state.task_template.mode = mode;
      state.task_template.props.selected = selected;
    },
    setViewDocument: (state, action) => {
      const { open, selectedDocument = null, type } = action.payload;
      state.documentViewer.open = open;
      state.documentViewer.selectedDocument = selectedDocument;
      state.documentViewer.type = type;
    },
    setDocumentForEdit: (state, action) => {
      state.document.selected_document = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder;
    // .addCase(fetchCreateDocumentFormSchema.fulfilled, (state, action) => {
    //   state.document.props.formSchema = action.payload;
    // })
    // .addCase(fetchEditDocumentFormSchema.fulfilled, (state, action) => {
    //   let schema = action.payload;

    //   schema.properties["_id"] = {
    //     title: "ID",
    //     type: "string",
    //   };

    //   state.document.props.formSchema = action.payload;
    // })
    // .addCase(fetchDocumentFormData.fulfilled, (state, action) => {
    //   state.document.props.formData = action.payload;
    // })

    // .addCase(fetchSingleTaskTemplate.fulfilled, (state, action) => {
    //   state.task_template.props.formData = action.payload;
    // });
  },
});

export const {
  toggleModal,
  setDocumentFormData,
  // setTaskTemplateFormData,
  toggleTaskTemplateModal,
  // toggleDocumentLinksModal,
  setViewDocument,
  setDocumentForEdit,
} = modalsSlice.actions;
export default modalsSlice.reducer;

// export const fetchCreateDocumentFormSchema = createAsyncThunk(
//   "modals>fetchCreateDocumentFormSchema",
//   async (tab) => {
//     // if (tab === "entities") {

//     // const response = await api.get(`/items/form/plain_document/create`);
//     const response = await api.get(`/items/form/plain_document_entity/create`);
//     return response?.data;
//     // }
//     // else {

//     //     const response = await api.get(`/items/form/plain_document/create`);
//     //     return response?.data;
//     // }
//   }
// );

// export const fetchEditDocumentFormSchema = createAsyncThunk(
//   "modals>fetchEditDocumentFormSchema",
//   async (tab) => {
//     // if (tab === "entities") {
//     const response = await api.get(`/items/form/plain_document_entity/update`);
//     return response?.data;
//     // }
//     // else {

//     //     const response = await api.get(`/items/form/plain_document/update`);
//     //     return response?.data;
//     // }
//   }
// );

// export const fetchDocumentFormData = createAsyncThunk(
//   "modals>fetchDocumentFormData",
//   async (document) => {
//     const response = await api.get(
//       `/documents/query?active=true&_id=${document._id}`
//     );
//     if (response.data.success) {
//       const {
//         uploaded_filename,
//         base_id,
//         active,
//         stamp,
//         kind,
//         chunkSize,
//         mdf,
//         length,
//         uploadDate,
//         md5,
//         ...data
//       } = response.data.data[0];
//       return data;
//       //   return response.data.data[0];
//     } else {
//       return {};
//     }
//   }
// );

// export const fetchSingleTaskTemplate = createAsyncThunk(
//   "modals>fetchSingleTaskTemplate",
//   async (id, thunkAPI) => {
//     const response = await api.get(`/items/item_data/${id}?output_format=flat`);
//     // await thunkAPI.dispatch(fetchTaskDocuments(id))
//     return response?.data?.data;
//   }
// );
