import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("theme")
  ? JSON.parse(localStorage.getItem("theme"))
  : {
      sidebarCollapsed: false,
      darkModeEnabled: true,
      menuMode: "vertical",
      customTheme: {
        token: {
          wireframe: true,
          fontSize: 13,
          colorPrimary: "#00bd52",
          borderRadius: 0,
        },
      },
      direction: "ltr",
    };

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      localStorage.setItem(
        "theme",
        JSON.stringify({
          ...state,
          sidebarCollapsed: !state.sidebarCollapsed,
        })
      );

      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    setSidebar: (state, action) => {
      localStorage.setItem(
        "theme",
        JSON.stringify({
          ...state,
          sidebarCollapsed: action.payload,
        })
      );
      state.sidebarCollapsed = action.payload;
    },
    setTheme: (state, action) => {
      state.customTheme = action.payload;
      localStorage.setItem(
        "theme",
        JSON.stringify({
          ...state,
          customTheme: action.payload,
        })
      );
    },
    setDarkMode: (state, action) => {
      state.darkModeEnabled = action.payload;
      localStorage.setItem(
        "theme",
        JSON.stringify({
          ...state,
          darkModeEnabled: action.payload,
        })
      );
    },
    setDirection: (state, action) => {
      localStorage.setItem(
        "theme",
        JSON.stringify({
          ...state,
          direction: action.payload,
        })
      );
      state.direction = action.payload;
    },
    setMenuMode: (state, action) => {
      console.log("Action dispatched > ", state, action);
      localStorage.setItem(
        "theme",
        JSON.stringify({
          ...state,
          menuMode: action.payload,
        })
      );
      state.menuMode = action.payload;
    },
  },
});

export const {
  toggleSidebar,
  setSidebar,
  setTheme,
  setDirection,
  setComponentSize,
  setDarkMode,
  setMenuMode,
} = themeSlice.actions;
export default themeSlice.reducer;
