import "antd/dist/reset.css";
import React from "react";
import { useSelector } from "react-redux";
import RoutesConfig from "./Routes";
import { ConfigProvider, Spin, theme } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
Spin.setDefaultIndicator(<LoadingOutlined />);
function App() {
  const { darkModeEnabled } = useSelector((state) => state.theme);
  const themeAlgorithm = React.useMemo(() => {
    return darkModeEnabled
      ? [theme.darkAlgorithm, theme.compactAlgorithm]
      : [theme.compactAlgorithm];
  }, [darkModeEnabled]);

  

  return (
    <ConfigProvider
      theme={{
        algorithm: themeAlgorithm,
        token: {
          colorPrimary: "#16b04f",
          colorSuccess: "#42bf04",
          colorInfo: "#16804f",
          colorError: "#f9262a",
          colorWarning: "#f7aa11",
          borderRadius: 10,
          wireframe: false,
          fontSize: 14,
        },
      }}
      prefixCls="frq"
    >
      <RoutesConfig />
    </ConfigProvider>
  );
}

export default App;
