import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  documentsLoading: [],
  documentsList: [],
  // documentViewModalOpen: false,
  // selectedDocumentForView: null,
  // viewDocumentQueryParams: {
  //   page: null,
  // },
};

// export const fetchDocuments = createAsyncThunk(
//   "documents>list",
//   async (searchParams) => {
//     var kind = searchParams.get("tab");
//     var fund_id = searchParams.get("fund_id");
//     var tags = searchParams.getAll("tags");

//     // console.log("Kind is ", kind, "Fund_id is", fund_id, "Tags are", tags)

//     var query = {};

//     if (kind == "references") {
//       const response = await api.get(
//         `/documents/query?active=true&kind=reference_document`
//       );
//       return response?.data?.data;
//     } else {
//       if (kind === "tasks") {
//         query = {
//           item_query: { kind: { $eq: "task" } },
//           document_query: {},
//         };
//         if (fund_id) {
//           query.item_query.fund_id = fund_id;
//         }
//       } else if (kind === "entities") {
//         // query = {
//         //   item_query: { kind: { $ne: "task" } },
//         //   document_query: {},
//         // };
//         query = {
//           item_query: {
//             kind: { $in: ["manager", "shareclass", "fund", "subfund"] },
//           },
//           document_query: {},
//         };
//         if (fund_id) {
//           query.item_query._id = fund_id;
//         }
//       } else {
//         query = {
//           item_query: {},
//           document_query: {},
//         };
//         if (fund_id) {
//           query.item_query._id = fund_id;
//         }
//       }

//       if (tags.length !== 0) {
//         query.document_query.tags = { $all: tags };
//       }

//       const response = await api.post(`/documents/documents_for_items/`, query);
//       return response?.data?.data;
//     }
//   }
// );

// export const fetchEntityTreeView = createAsyncThunk(
//     "tasks>tree_view",
//     async (query = "") => {
//         const response = await api.get(
//             `/items/query?output_format=tree&kind=manager`
//         );
//         return response?.data?.data;
//     }
// );
// export const fetchSingleTask = createAsyncThunk(
//     "tasks>retrieve",
//     async (id, thunkAPI) => {
//         const response = await api.get(`/items/item_data/${id}?output_format=flat`);
//         // await thunkAPI.dispatch(fetchTaskDocuments(id))
//         return response?.data?.data;
//     }
// );
// export const fetchCollegueNamesList = createAsyncThunk(
//     "tasks>collegue_names",
//     async () => {
//         const response = await api.get(`/users/get_colleagues`);
//         return response?.data;
//     }
// );
// export const fetchItemsList = createAsyncThunk(
//     "tasks>items_list",
//     async () => {
//         const response = await api.get(`/items/query?output_format=flat&kind=manager&kind=fund&kind=subfund&kind=shareclass&kind=group`);
//         return response?.data?.data;
//     }
// );
// export const deleteTaskAction = createAsyncThunk(
//     "tasks>delete",
//     async (key, thunkAPI) => {
//         const resp = await api.delete("/items/delete", {
//             data: {
//                 _id: key,
//             },
//         });
//         if (resp.data.success) {
//             await thunkAPI.dispatch(fetchTasksList(thunkAPI.getState().tasks.query));
//             message.success("Deleted Successfully");
//         } else {
//             message.error(resp.data.error);
//         }
//     }
// );

const documentsSlice = createSlice({
  name: "documentState",
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    // handleDocumentViewModalOpen: (state, action) => {
    //   state.documentViewModalOpen = true;
    //   state.selectedDocumentForView = action.payload;
    // },
    // handleDocumentViewModalClose: (state) => {
    //   state.documentViewModalOpen = false;
    //   state.selectedDocumentForView = null;
    // },

  },
  extraReducers: () => {
    // builder.addCase(fetchDocuments.pending, (state) => {
    //   state.documentsLoading = true;
    // });
    // builder.addCase(fetchDocuments.fulfilled, (state, action) => {
    //   state.documentsList = action.payload;
    //   state.documentsLoading = false;
    // });
  },
});

export const { setQuery} = documentsSlice.actions;
export default documentsSlice.reducer;
