/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  selectTemplates : [],
  templatesList: [],
  itemsList: [],
  collegue_names: [],
  groups_tree_list: [],
  funds_tree_list: [],
  selectedTask: null,
  editTask: null,
  drawerOpen: false,
  singleLoading: false,
  listLoading: false,
  formMode: "add",
  formModalOpen: false,
  formSchema: {},
  createTaskTemplateFormSchema: {},
  editTaskTemplateFormSchema: {},
  entityTreeDrawerOpen: false,
  filtersSidebarCollapsed: false,
  entityTreeViewList: [],
  documentsList: [],

  documentVersionDrawerOpen: false,
  selectedDocumentForVersions: null,

  documentViewModalOpen: false,
  selectedDocumentForView: null,

  document_versions: [],
};


// export const fetchEntityTreeView = createAsyncThunk(
//   "templates>tree_view",
//   async (query = "") => {
//     const response = await api.get(
//       `/items/query?output_format=tree&kind=manager`
//     );
//     return response?.data?.data;
//   }
// );

// export const fetchCollegueNamesList = createAsyncThunk(
//   "templates>collegue_names",
//   async () => {
//     const response = await api.get(`/users/get_colleagues`);
//     return response?.data;
//   }
// );
// export const fetchItemsList = createAsyncThunk(
//   "templates>items_list",
//   async () => {
//     const response = await api.get(
//       `/items/query?output_format=flat&kind=manager&kind=fund&kind=subfund&kind=shareclass&kind=group`
//     );
//     return response?.data?.data;
//   }
// );
// export const deleteTaskTemplateAction = createAsyncThunk(
//   "templates>delete",
//   async (key, thunkAPI) => {
//     const resp = await api.delete("/items/delete", {
//       data: {
//         _id: key,
//       },
//     });
//     if (resp.data.success) {
//       await thunkAPI.dispatch(
//         // fetchTaskTemplatesList(thunkAPI.getState().templates.query)
//       );
//       message.success("Deleted Successfully");
//     } else {
//       message.error(resp.data.error);
//     }
//   }
// );

// export const fetchEntityTemplates = createAsyncThunk(
//   "templates/treeView>fetchTemplatesForEntity",
//   async (id, thunkAPI) => {
//     const resp = await api.get(
//       `/items/query?fund_id=${id}&kind=task&active=true&output_format=flat`
//     );

//     // console.log("old State is ",thunkAPI.getState().templates.treeViewTemplatelist)
//     return resp?.data?.data;
//   }
// );

// export const fetchCreateTaskTemplateFormSchema = createAsyncThunk(
//   "templates/FormSchema>Create",
//   async () => {
//     const resp = await api.get(`/items/form/task/create`);
//     return resp?.data;
//   }
// );
// export const fetchEditTaskTemplateFormSchema = createAsyncThunk(
//   "templates/FormSchema>Update",
//   async () => {
//     const resp = await api.get(`/items/form/task/update`);
//     return resp?.data;
//   }
// );

const templatesSlice = createSlice({
  name: "templatesState",
  initialState,
  reducers: {
    setSelectedTemplates : (state, action)=>{
      state.selectTemplates = action.payload;
    },
    setTemplatesList: (state, action) => {
      state.templatesList = action.payload;
    },
    setCollegueList: (state, action) => {
      state.collegue_names = action.payload;
    },
    setGroupsTreeList: (state, action) => {
      state.groups_tree_list = action.payload;
    },
    setFundsTreeList: (state, action) => {
      state.funds_tree_list = action.payload;
    },
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },
    setSingleLoading: (state, action) => {
      state.singleLoading = action.payload;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setFormMode: (state, action) => {
      state.formMode = action.payload;
    },
    setFormModalOpen: (state, action) => {
      state.formModalOpen = action.payload;
    },
    handleFormModalCancel: (state, action) => {
      state.selectedTask = null;
      state.formMode = "add";
      state.formModalOpen = false;
    },
    handleFormModalCreate: (state, action) => {
      state.formMode = "add";
      state.formModalOpen = true;
    },
    handleFormModalEdit: (state, action) => {
      state.selectedTask = action.payload;
      state.formMode = "edit";
      state.formModalOpen = true;
    },
    handleDrawerClose: (state, action) => {
      state.selectedTask = null;
      state.documentsList = [];
      state.drawerOpen = false;
    },
    setFormSchema: (state, action) => {
      state.formSchema = action.payload;
    },
    setEditTask: (state, action) => {
      state.editTask = action.payload;
    },
    setEntityTreeDrawerOpen: (state, action) => {
      state.entityTreeDrawerOpen = action.payload;
    },
    setFiltersSidebarCollapsed: (state, action) => {
      state.filtersSidebarCollapsed = action.payload;
    },
    handleDocumentVersionDrawerOpen: (state, action) => {
      state.documentVersionDrawerOpen = true;
      state.selectedDocumentForVersions = action.payload;
    },
    handleDocumentVersionDrawerClose: (state, action) => {
      state.documentVersionDrawerOpen = false;
      state.selectedDocumentForVersions = null;
    },
    handleDocumentViewModalOpen: (state, action) => {
      state.documentViewModalOpen = true;
      state.selectedDocumentForView = action.payload;
    },
    handleDocumentViewModalClose: (state, action) => {
      state.documentViewModalOpen = false;
      state.selectedDocumentForView = null;
    },
    setDocumentsList: (state, action) => {
      state.documentsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder
  
      // .addCase(fetchEntityTreeView.pending, (state, action) => {
      //   state.listLoading = true;
      // })
      // .addCase(fetchEntityTreeView.fulfilled, (state, action) => {
      //   state.entityTreeViewList = action.payload;
      // })
      
      // .addCase(fetchCollegueNamesList.fulfilled, (state, action) => {
      //   state.collegue_names = action.payload;
      // })
      // .addCase(fetchItemsList.fulfilled, (state, action) => {
      //   state.itemsList = action.payload;
      // })
      // .addCase(fetchCreateTaskTemplateFormSchema.fulfilled, (state, action) => {
      //   let schema = action.payload;
      //   //   schema.properties.recurrence = {
      //   //     "title": "Recurrence",
      //   //     "type": "string",
      //   // }
      //   state.createTaskTemplateFormSchema = schema;
      // })
      // .addCase(fetchEditTaskTemplateFormSchema.fulfilled, (state, action) => {
      //   state.editTaskTemplateFormSchema = action.payload;
      // });
  },
});

export const {
  setTemplatesList,
  setCollegueList,
  setGroupsTreeList,
  setFundsTreeList,
  setDrawerOpen,
  setSelectedTask,
  setSingleLoading,
  setListLoading,
  setFormMode,
  setFormModalOpen,
  handleFormModalCancel,
  handleFormModalCreate,
  handleFormModalEdit,
  setFormSchema,
  setEditTask,
  setEntityTreeDrawerOpen,
  setFiltersSidebarCollapsed,
  handleDrawerClose,
  handleDocumentVersionDrawerClose,
  handleDocumentVersionDrawerOpen,
  setDocumentsList,
  handleDocumentViewModalClose,
  handleDocumentViewModalOpen,
  // setSelectedTemplates,
} = templatesSlice.actions;
export default templatesSlice.reducer;
