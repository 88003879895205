/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  
};

const queryParamsSlice = createSlice({
  name: "queryParams",
  initialState,
  reducers: {
    setQueryParams: (state, action) => {
      const { path, payload} = action.payload;
      state[path] = payload;
    },
  },
});

export const { setQueryParams } = queryParamsSlice.actions;
export default queryParamsSlice.reducer;
