import { createSlice } from "@reduxjs/toolkit";

// const initiateLogin = createAsyncThunk("auth/login")

const { access, refresh, user } = localStorage.getItem("auth")
  ? JSON.parse(localStorage.getItem("auth"))
  : {};
const initialState = {
  user: user,
  access: access,
  refresh: refresh,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginCredentials: (state, action) => {
      const { access, refresh } = action.payload;
      state.access = access;
      state.refresh = refresh;
    },
    setMfaCredentials: (state, action) => {
      const { access, refresh, user } = action.payload;

      state.user = user;
      state.access = access;
      state.refresh = refresh;

      localStorage.setItem("auth", JSON.stringify(action.payload));
    },
    setRefreshCredentials: (state, action) => {
      const { access, refresh } = action.payload;
      state.access = access;
      state.refresh = refresh;
      localStorage.setItem("auth", JSON.stringify(action.payload));
    },
    logOut: (state) => {
      localStorage.removeItem("auth");
      state.user = null;
      state.access = null;
      state.refresh = null;
    },
  },
});

export const {
  setLoginCredentials,
  setMfaCredentials,
  setRefreshCredentials,
  logOut,
} = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.access;
