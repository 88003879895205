/* eslint-disable no-unused-vars */
import {  createSlice } from "@reduxjs/toolkit";

const initialState = {
  // itemsList: [],
  // selectedTasks : [],
  // bulkTaskUpdateDrawerOpen : false,
  formMode: "add",
  formModalOpen: false,

};




// export const deleteTaskAction = createAsyncThunk(
//   "tasks>delete",
//   async (key, thunkAPI) => {
//     const resp = await api.delete("/items/delete", {
//       data: {
//         _id: key,
//       },
//     });
//     if (resp.data.success) {
//       // await thunkAPI.dispatch(fetchTasksList(thunkAPI.getState().tasks.query));
//       message.success("Deleted Successfully");
//     } else {
//       message.error(resp.data.error);
//     }
//   }
// );




const tasksSlice = createSlice({
  name: "tasksState",
  initialState,
  reducers: {

   
    
    // setSelectedTasks : (state, action) => {
    //   state.selectedTasks = action.payload;
    // },
    setFormMode: (state, action) => {
      state.formMode = action.payload;
    },
    setFormModalOpen: (state, action) => {
      state.formModalOpen = action.payload;
    },
    handleFormModalCancel: (state, action) => {
      
      state.formMode = "add";
      state.formModalOpen = false;
    },
    handleFormModalCreate: (state, action) => {
      state.formMode = "add";
      state.formModalOpen = true;
    },
    handleFormModalEdit: (state, action) => {
    
      state.formMode = "edit";
      state.formModalOpen = true;
    },
    
   
    
   
    
  },
  extraReducers: () => {
    

  },
});

export const {
  setFormMode,
  setFormModalOpen,
  handleFormModalCancel,
  handleFormModalCreate,
  handleFormModalEdit,
  

} = tasksSlice.actions;
export default tasksSlice.reducer;
