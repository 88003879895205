import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import { Routes, Route } from "react-router-dom";
import CustomRouter from "./components/CustomRouter";
import customHistory from "./common/custom_history";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./index.css";
import WebSocketProvider from "context/WebSocket";

const root = ReactDOM.createRoot(document.getElementById("root"));
const query_client = new QueryClient(
  {
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime : Infinity,
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  <QueryClientProvider client={query_client}>
    <Provider store={store}>
      <WebSocketProvider>
        <CustomRouter history={customHistory}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </CustomRouter>
      </WebSocketProvider>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} position="left"  buttonPosition="bottom-left"/>
  </QueryClientProvider>
);