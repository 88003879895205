import { createSlice } from "@reduxjs/toolkit";
// import { message } from "antd";

const initialState = {
  conversationDrawerOpen: false,
  chatDrawerOpen: false,
  selectedConversation: {},

  groupChatDrawerOpen: false,
  groupDetailsDrawerOpen: false,
  selectedGroup: {},

  supportChatPopupOpen: false,
  // users: [],
  // chat_loading: false,
  // chat_list: [],
  // chat_loaded: false,
  // conversation_loading: false,
  // conversation_list: [],
};

// export const fetchChatUsers = createAsyncThunk("chats>users", async () => {
//   const response = await api.get(`/chat/get/users`);

//   return response?.data?.data;
// });
// export const fetchConversationList = createAsyncThunk(
//   "chats>conversations",
//   async () => {
//     const response = await api.get(`/chat/user/conversations`);

//     if (response.data.success) {
//       return response?.data?.data;
//     } else {
//       // message.error("No Conversation")
//       return [];
//     }
//   }
// );
// export const fetchChatMessages = createAsyncThunk(
//   "chats>messages",
//   async (query, { rejectWithValue }) => {
//     let { conversation_id, page_no } = query;
//     try {
//       const response = await api.get(
//         `/chat/user/messages?conversation_id=${conversation_id}&page=${page_no}`
//       );

//       if (response.data.success) {
//         return response?.data?.data;
//       } else {
//         return [];
//       }
//       // console.log("messages response ", response.data.success)
//     } catch (err) {
//       console.log("Error while fetching messages", err);
//       return rejectWithValue([]);
//     }
//   }
// );
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setConversationDrawerOpen: (state, action) => {
      state.conversationDrawerOpen = action.payload;
    },
    setChatDrawerOpen: (state, action) => {
      state.chatDrawerOpen = action.payload;
    },
    // setSelectedUser: (state, action) => {
    //     state.selectedUser = action.payload
    // },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },

    toggleGroupChatDrawer: (state, action) => {
      const { open, group } = action.payload;
      state.groupChatDrawerOpen = open;
      state.selectedGroup = group;
    },
    setGroupDetailsDrawerOpen: (state, action) => {
      state.groupDetailsDrawerOpen = action.payload;
    },
    setSupportChatPopupOpen: (state, action) => {
      state.supportChatPopupOpen = action.payload;
    },
    // setConversationList: (state, action) => {
    //   state.conversation_list = action.payload;
    // },
    // addNewMessageToChatList: (state, action) => {
    //   state.chat_list = [...state.chat_list, action.payload];
    // },
  },
  // extraReducers: (builder) => {

  // builder.addCase(fetchConversationList.pending, (state) => {
  //   state.conversation_loading = true;
  // });
  // builder.addCase(fetchConversationList.fulfilled, (state, action) => {
  //   state.conversation_loading = false;
  //   state.conversation_list = action.payload;
  // });
  // builder.addCase(fetchChatMessages.pending, (state) => {
  //   state.chat_loading = true;
  // });
  // builder.addCase(fetchChatMessages.fulfilled, (state, action) => {
  //   state.chat_loading = false;

  //   const new_messages = action.payload;

  //   if (new_messages.length === 0) {
  //     state.chat_loaded = true;
  //     message.error("No more messages");
  //   } else {
  //     state.chat_list = [...state.chat_list, ...new_messages];
  //   }
  //   // console.log("messages are undefined",new_messages)
  // });
  // },
});

export const {
  setConversationDrawerOpen,
  setChatDrawerOpen,
  setSelectedConversation,
  toggleGroupChatDrawer,
  setGroupDetailsDrawerOpen,
  setSupportChatPopupOpen,
} = chatSlice.actions;
export default chatSlice.reducer;
